@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Chewy&display=swap');

.cf::after {
  content: "";
  display: block;
  clear: both;
}
.display-width{
  width: 100% !important;
}
html{
  overflow-x: hidden;
  position: relative;
  width: 100vw;
  min-height: 100%;
  font-family: "nunito", 'Lisong Pro', 'PMingLiU', 'PingFang TC','Microsoft JhengHei',"微軟正黑體",STXihei, sans-serif;
	font-size: 62.5%;
}
body{
  overflow-x: hidden;
  background: url("../img/bg_pc.png") no-repeat top/cover;
}
.mv-block{
  overflow-x: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
}
.mv-block .img{
  position: relative;
  top: 0;
  left: 50%;
  margin: 0 0 0 -600px;
  width: 1200px;
  height: auto;
  z-index: 2;
}
.mv-block .img img{
  width: 100%;
  height: auto;
}
#root,.root{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  -webkit-transform: translate(-50%, -60%);
  -ms-transform: translate(-50%, -60%);
  width: 600px;
  margin: 0 auto 0;
  padding: 60px 0;
  background: rgba(255, 255, 255, .85);
  text-align: center;
  z-index: 999;
}
.ttl{
  margin-top: 30px;
  color: #ffa64d;
  font-family: chewy, helvetica, 'Lisong Pro', 'PMingLiU', 'PingFang TC','Microsoft JhengHei',"微軟正黑體",STXihei, sans-serif;
  font-size: 3.6rem;
}
.l-txt{
  margin-top: 10px;
  color: #000;
  font-family: chewy, helvetica, 'Lisong Pro', 'PMingLiU', 'PingFang TC','Microsoft JhengHei',"微軟正黑體",STXihei, sans-serif;
  font-size: 3.6rem;
}
.s-txt,.address{
  margin-top: 10px;
  font-size: 1.2rem;
}
.mint-cnt .cnt-block + .s-txt{
  margin-top: 20px;
  font-size: 2rem;
  font-weight: bold;
}
.s-txt + .s-txt{
  margin-top: .5vw;
}
.total-supply{
  margin-top: 20px;
  color: #000;
  font-family: chewy, helvetica, 'Lisong Pro', 'PMingLiU', 'PingFang TC','Microsoft JhengHei',"微軟正黑體",STXihei, sans-serif;
  font-size: 3.6rem;
}
.btn-typ01,
.btn-typ02,
.btn-typ03{
  margin-top: 30px;
}
.btn-typ01 button,
.btn-typ02 button,
.btn-typ03 button{
  display: block;
  min-width: 230px;
  margin: 0 auto;
  padding: 12px 38px;
  background: rgb(237, 131, 217);
  border: none;
  border-radius: 50px;
  font-family: chewy, helvetica, 'Lisong Pro', 'PMingLiU', 'PingFang TC','Microsoft JhengHei',"微軟正黑體",STXihei, sans-serif;
  color: #fff;
  font-weight: bold;
  font-size: 1.6rem;
  transition: .3s all;
  letter-spacing: .5rem;
}
.btn-typ01 button:hover,
.btn-typ02 button:hover,
.btn-typ03 button:hover{
  background: #ffa64d;
  transition: .6s all;
}
.cnt-block{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
  margin: 20px auto 0;
}
.cnt-block .num{
  width: 140px;
  margin: 0 50px;
  color: #000;
  font-family: chewy, helvetica, 'Lisong Pro', 'PMingLiU', 'PingFang TC','Microsoft JhengHei',"微軟正黑體",STXihei, sans-serif;
  font-size: 4.8rem;
}
.cnt-block button{
  display: block;
  width: 40px;
  height: 40px;
  background: rgb(237, 131, 217);
  border: none;
  border-radius: 50%;
  color: #fff;
  font-family: chewy, helvetica, 'Lisong Pro', 'PMingLiU', 'PingFang TC','Microsoft JhengHei',"微軟正黑體",STXihei, sans-serif;
  font-weight: bold;
  font-size: 2rem;
  transition: .3s all;
}
.cnt-block #minus10{
  margin-right: 10px;
}
.cnt-block #plus10{
  margin-left: 10px;
}
.cnt-block button:hover{
  background: #ffa64d;
  transition: .6s all;
}
.bg-slide{
  overflow: hidden;
  height: 30vh;
  margin-top: 70vh;
}
.bg-slide li{
  width: 30vh !important;
  margin: 0 20px;
}
.bg-slide li img{
  width: 100% !important;
  height: auto !important;
}


@media only screen and (max-width: 768px) {
/* -------------------------------- 
common
-------------------------------- */
body{
  overflow-x: hidden;
  background: url("../img/bg_sp.png") no-repeat top/cover;
}
#root,.root{
  width: 90vw;
}
.bg-slide{
  height: 20vh;
  margin-top: 80vh;
}
.bg-slide li{
  width: 20vh !important;
  margin: 0;
}
.address{
  width: 80vw;
  margin: 10px auto 0;
}
.cnt-block .num{
  margin: 0 25px;
}
.cnt-block button{
  width: 30px;
  height: 30px;
  font-size: 1.4rem;
}
.cnt-block #minus10{
  margin-right: 10px;
}
.cnt-block #plus10{
  margin-left: 10px;
}
}


@media only screen and (max-width: 375px) {
/* -------------------------------- 
common
-------------------------------- */
#root .img,.root .img{
  width: 85vw;
  margin: 0 auto;
}
.cnt-block .num{
  margin: 0 15px;
}
.btn-typ01 button,
.btn-typ02 button,
.btn-typ03 button{
  font-size: 1.2rem;
}
}



